import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:_vm.isMobile() ? '' : 'dxa_modal'},[_c(VCardTitle,[_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(_vm._s(_vm.$t("manage_category")))])]),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":_vm.lazy},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkForm()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":_vm.$t('name'),"rules":[_vm.required]},model:{value:(_vm.category.Name),callback:function ($$v) {_vm.$set(_vm.category, "Name", $$v)},expression:"category.Name"}}),_c('span',[_vm._v(_vm._s(_vm.$t("is_register_shown")))]),_c(VRadioGroup,{attrs:{"center":"","row":""},model:{value:(_vm.category.ShownRegister),callback:function ($$v) {_vm.$set(_vm.category, "ShownRegister", $$v)},expression:"category.ShownRegister"}},[_c(VRadio,{attrs:{"label":_vm.$t('yes'),"value":true}}),_c(VRadio,{attrs:{"label":_vm.$t('no'),"value":false}})],1),_c(VCardActions,{staticClass:"pl-0 dxa_modal_actions"},[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary","min-width":"100","loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":function($event){return _vm.$emit('close', null)}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1),(_vm.error)?_c(VAlert,{staticClass:"ma-2",attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.$t(_vm.error)))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }