<template>
  <v-card :class="isMobile() ? '' : 'dxa_modal'">
    <v-card-title>
      <h4 class="dxa_modal_title h4">{{ $t("manage_category") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form
        @submit.stop.prevent="checkForm()"
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
      >
        <v-text-field
          :label="$t('name')"
          :rules="[required]"
          v-model="category.Name"
        />

        <span>{{ $t("is_register_shown") }}</span>
        <v-radio-group center v-model="category.ShownRegister" row>
          <v-radio :label="$t('yes')" :value="true"></v-radio>
          <v-radio :label="$t('no')" :value="false"></v-radio>
        </v-radio-group>

        <!-- <span>{{ $t("is_pipeline_shown") }}</span>
        <v-radio-group center v-model="category.ShownPipeline" row>
          <v-radio :label="$t('yes')" :value="true"></v-radio>
          <v-radio :label="$t('no')" :value="false"></v-radio>
        </v-radio-group> -->

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close', null)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-alert class="ma-2" v-if="error" type="error">{{ $t(error) }}</v-alert>
    </v-card-text>
  </v-card>
  <!-- </v-container> -->
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "AddCategoryModal",

  props: {
    category_prop: Object,
  },

  async created() {
    if (this.category_prop != null) {
      this.edit = true;
      this.category = {
        Id: this.category_prop.id,
        Name: this.category_prop.name,
        ShownRegister: this.category_prop.shownRegister,
        // ShownPipeline: this.category_prop.ShownPipeline,
      };
    } else {
      this.edit = false;
      this.category = {
        Name: null,
        ShownRegister: false,
        // ShownPipeline: false,
      };
    }
  },

  data() {
    return {
      apiService: new ApiService(),
      loading: false,
      edit: false,
      valid: true,
      lazy: false,
      error: null,

      category: {
        Name: null,
        ShownRegister: false,
        // ShownPipeline: false,
      },
    };
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },

  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        await this.submit();
      } else {
        this.loading = false;
      }
    },

    submit: async function () {
      this.error = null;
      if (this.edit) {
        await this.apiService
          .putRequest("category/edit", this.category)
          .then((result) => {
            this.$emit("close", this.category);
          })
          .catch((err) => {
            this.error = err.body.message;
          });
      } else {
        await this.apiService
          .postRequest("category/new", this.category)
          .then((result) => {
            this.$emit("close", JSON.parse(result.message));
          })
          .catch((err) => {
            this.error = err.body.message;
          });
      }
      this.loading = false;
    },
  },
};
</script>
