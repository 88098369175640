<template>
  <v-container v-if="all_loaded" id="category-table" fluid tag="section">
    <base-material-card
      icon="mdi-order-alphabetical-ascending"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("category", 2) }}</b>
        </h1></template
      >
      <v-btn
        color="primary"
        rounded
        @click="open_dialog(null)"
        absolute
        fab
        top
        right
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th class="text-center">{{ $t("edit") }}</th>
            <th class="text-center">{{ $t("remove") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(category, index) in categories" :key="index">
            <td>{{ $t(category.key) }}</td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1 secondary"
                @click="open_dialog(category)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(category)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-if="dialog" v-model="dialog" scrollable>
      <AddCategoryModal
        :category_prop="editCategory"
        @close="reset_dialog"
      ></AddCategoryModal>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name"
        @close="delete_dialog = false"
        @delete="delete_category"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import AddCategoryModal from "@/views/pages/category/form/AddCategoryModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";

export default {
  name: "CategoryTable",

  components: {
    AddCategoryModal,
    DeleteConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    all_loaded: false,
    dialog: false,
    delete_obj: null,
    delete_dialog: false,
    editCategory: null,
    categories: [],
  }),
  async created() {
    this.apiService
      .getRequest("category/list-all")
      .then((resp) => {
        this.categories = resp.content;
        this.all_loaded = true;
      })
      .catch((error) => {
        this.all_loaded = true;
      });
  },
  methods: {
    open_delete_dialog: function (category) {
      this.delete_dialog = true;
      this.delete_obj = category;
    },
    open_dialog: function (category) {
      this.editCategory = category;
      this.dialog = true;
    },
    reset_dialog(category) {
      if (category != null) {
        this.$toast.success(this.$t("category_saved"));
        if (this.editCategory) {
          var index = this.categories.indexOf(this.editCategory);
          this.categories[index] = category;
        } else {
          this.categories.push(category);
        }
      }
      this.editCategory = null;
      this.dialog = false;
    },
    delete_category: async function (category) {
      var index = this.categories.indexOf(category);
      this.categories.splice(index, 1);
      this.delete_dialog = false;
      await this.apiService
        .deleteRequest(`category/delete/${category.id}`)
        .then((resp) => {})
        .catch((error) => {});
    },
  },
};
</script>
